import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useGetAllCategoryListQuery } from "../../../utils/api/category/category.api";
import { usePagination } from "../../../utils/hooks";
import Accordion from "react-bootstrap/Accordion";
import { AlertInfo } from "../../common/Alert";
import { QueryUrl, sortByAlphabetical } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";


function FilterItems({
  searchParams,
  setSearchParams,
  query,
  setQuery,
  setPage,
}) {
  const [selectedCat, setSelectedCat] = React.useState<any>(
    searchParams?.get("sous_categorie")
      ? JSON.parse(searchParams?.get("sous_categorie") || "[]")
      : []
  );
  const [parent, setParent] = React.useState<any>(
    searchParams?.get("categorie") || ""
  );
  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("prix_min") || "",
    max: searchParams?.get("prix_max") || "",
  });
  const {
    data = { results: [] },
    isLoading,
    refetch,
  } = useGetAllCategoryListQuery();

  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(key);
  };

  React.useEffect(() => {
    let params = QueryUrl("", query);
    setSearchParams(params);
  }, [query]);

  React.useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      sous_categorie: selectedCat?.length ? JSON.stringify(selectedCat) : "",
    };
    // console.log("query selectedCat", queryCopy);

    setQuery(queryCopy);
  }, [selectedCat]);

  const handleFilter = () => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      ...{ prix_min: value?.min, prix_max: value?.max },
    };

    setPage(1);
    setQuery(queryCopy);
  };

  useEffect(() => {
    handleFilter();
  }, [value]);

  const handleChangeParent = (item) => {
    setParent(item?.slug);
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      categorie: item?.slug,
      sous_categorie: [],
    };
    // console.log("query selectedCat", queryCopy);
    setSelectedCat([]);
    setQuery(queryCopy);
  };

  const handleSelectedCat = (e: ChangeEvent<HTMLInputElement>, item) => {
    let selectedCopy = [...selectedCat];
    if (item?.slug !== parent) {
      handleChangeParent(item);
      selectedCopy= []
    }

    if (e?.target?.checked) {
      selectedCopy?.push(e?.target?.value);
    } else {
      selectedCopy = selectedCopy?.filter((el) => el !== e?.target?.value);
    }
    setSelectedCat(selectedCopy);

    setPage(1);
  };

  const handleClick = (item) => {
    handleToggle(item?.id)
    setParent(item?.slug);
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      categorie: item?.slug,
      sous_categorie: [],
    };
    // console.log("query selectedCat", queryCopy);
    setSelectedCat([]);
    setQuery(queryCopy);
    setPage(1);
  };

  return (
    <div className="col-lg-3 col-md-5 col-left-achat-page">
      <div
        className="content-col-left-achat-page"
        style={{ minHeight: "70rem" }}
      >
        <div className="content-others-value my-4 pb-3">
          <p className="content-title-section-left pb-2">Catégories</p>

          <div className="categories_and_subcategories">
            {!!isLoading && <p>Loading...</p>}

            <Accordion
              className="category-filter-acordion"
              id="accordionCategories"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              {!isLoading &&
                (sortByAlphabetical(data?.results)?.length ? (
                  sortByAlphabetical(data?.results)?.map((category, i) => (
                    <Accordion.Item
                      eventKey={`${i}`}
                      key={category?.id}
                      className="category-filter-acordion-item mb-1"
                    >
                      <Accordion.Header
                        className="mb-0"
                        onClick={() => handleClick(category)}
                      >
                        <span className="category_parent_name"
                          style={{
                            color: category?.id == activeKey ? "#c39a6c": "#f7ded3",
                          }}
                        >
                          {category?.nom}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {category?.sous_categorie?.length
                          ? sortByAlphabetical(category?.sous_categorie)?.map(
                              (sub) => (
                                <div
                                  className="content-col-form-register-tabs cpntrol mb-1"
                                  key={sub?.id}
                                >
                                  <label className="label-checkbox-register-tabs category_child_name">
                                    <input
                                      type="checkbox"
                                      className="input-checkbox-register-tabs me-2"
                                      checked={
                                        selectedCat &&
                                        selectedCat?.length &&
                                        selectedCat?.includes(sub?.slug)
                                      }
                                      value={sub?.slug}
                                      onChange={(e) =>
                                        handleSelectedCat(e, category)
                                      }
                                    />
                                    {sub?.nom}
                                  </label>
                                </div>
                              )
                            )
                          : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <AlertInfo message="Aucune catégorie disponible pour le moment" />
                ))}
            </Accordion>
          </div>
        </div>
        <p className="content-title-section-left">
          Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
        </p>
        <div className="pb-4 py-4">
          <label className="material-checkbox mb-3" key="10">
            <input
              type="radio"
              value={10}
              name="prix"
              defaultChecked={true}
              onChange={() => setValue({ min: "", max: "" })}
            />
            <span>Tout</span>
          </label>
          <label className="material-checkbox mb-3" key="10">
            <input
              type="radio"
              value={10}
              name="prix"
              checked={parseInt(value?.max) === 10000}
              onChange={() => setValue({ min: 0, max: 10000 })}
            />
            <span>Jusqu'a 10 000</span>
          </label>
          <label className="material-checkbox mb-3" key="25">
            <input
              type="radio"
              value={25}
              name="prix"
              checked={parseInt(value?.max) === 25000}
              onChange={() => setValue({ min: 10000, max: 25000 })}
            />
            <span>De 10 000 à 25 000</span>
          </label>
          <label className="material-checkbox mb-3" key="50">
            <input
              type="radio"
              value={50}
              name="prix"
              checked={parseInt(value?.max) === 50000}
              onChange={() => setValue({ min: 25000, max: 50000 })}
            />
            <span>De 25 000 à 50 000</span>
          </label>
          <label className="material-checkbox mb-3" key="60">
            <input
              className="radio-input"
              type="radio"
              value={60}
              name="prix"
              checked={parseInt(value?.max) === 90000000000}
              onChange={() => setValue({ min: 50000, max: 90000000000 })}
            />
            <span>De 50 000 et plus</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default FilterItems;
