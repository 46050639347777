type ENV = "dev" | "prod" | "demo";

export const currentEnv: string = "prod";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "6d28a0ae8b76e829c7b52822a260f8dbf0f32c0ff4a88b9b37b5aaefec1da974";
const VENDEUR_APIKEY_PROD =
  "6d28a0ae8b76e829c7b52822a260f8dbf0f32c0ff4a88b9b37b5aaefec1da974";

export const APP_URL_DEV = "https://easy-market.volkeno.com/";
export const APP_URL_DEMO = "https://easy-market.volkeno.com/";
export const APP_URL_PROD = "https://universastro.easymarket.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";

export const GoogleClientId =
  "252665710140-5euq0h9htj81foss4ur1gllbbl8qtig9.apps.googleusercontent.com";
